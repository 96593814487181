<template>
  <base-section id="services">
    <v-container class="fill-height px-4 py-0">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="1200"
        width="100%"
      >
        <v-container>
        <v-container>
          <v-row style="height: 60px;">
            <div
              class="d-flex align-center mx-auto"
            >
          <base-section-heading
            :title="$t('services')"
          >
          </base-section-heading>
            </div>
          <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              :width="$vuetify.breakpoint.width >= 768 ? '' : '150px'"
              height="60px"
              v-if="isNeedShowReset()"
              v-on:click="resetServiceFilter()"
            >
              <v-container class="fill-height">
                <v-row>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-img
                      height="22"
                      width="22"
                      src="@/assets/btn_reset.svg"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <div class="d-flex align-center">
                      <h4>
                        RESET
                      </h4>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-btn>
<!--          </div>-->
          </v-row>
        </v-container>
        </v-container>
        <v-container>
          <v-row>
            <v-col
              v-for="(service, i) in filteredServices"
              :key="i"
              cols="12"
              md="6"
              v-on:click="serviceClick(service)"
            >
              <!--card active: {{ isSelectedService(service) }}-->
              <base-avatar-card
                v-bind="service"
                vertical
                class="px-9 py-3"
                :class="[(isSelectedService(service)) ? 'card_service_active':'card_service']"
              >
              </base-avatar-card>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </v-container>
    <v-container class="fill-height px-4 py-0">
      <div
        v-if="isNeedShowMore"
        class="d-flex justify-center mx-auto"
      >
        <v-btn
          width="100%"
          min-height="60"
          class="btn_more mt-lg-12"
          @click="clickShowMore()"
        >
          {{ $t('showMore') }}
        </v-btn>
      </div>
    </v-container>
  </base-section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SectionServices',

    data: () => ({
      rows: 2,
      filteredServices: [],
    }),
    computed: {
      isNeedShowMore () {
        return (this.rows * ((this.$vuetify.breakpoint.width >= 768) ? 2 : 1)) < this.services.length
      },
      ...mapGetters({
        serviceSelectedItems: 'services/selectedItems',
        services: 'services/services',
      }),
    },
    watch: {
      services: function () {
        this.filterServices()
      },
      serviceSelectedItems: function () {
        this.filterServices()
      },
    },
    mounted () {
      this.$store.dispatch('services/fetchServices')
    },
    methods: {
      filterServices () {
        const minIndex = Math.min(this.services.length, this.rows * ((this.$vuetify.breakpoint.width >= 768) ? 2 : 1))
        console.log('minIndex: ' + minIndex + ' filteredServices.length :' + this.services.length)
        this.filteredServices = this.services.slice(0, minIndex)
      },
      serviceClick (service) {
        if (typeof this.serviceSelectedItems === 'undefined') {
          return
        }
        const index = this.serviceSelectedItems.findIndex(function (element, index, array) {
          return element.id === service.id
        })
        if (index >= 0) this.$store.dispatch('services/removeSelectedItem', { item: service })
        else this.$store.dispatch('services/addSelectedItem', { item: service })
        console.log('serviceClick: ' + JSON.stringify(this.serviceSelectedItems))
      },
      resetServiceFilter () {
        this.$store.dispatch('services/resetSelectedItem')
      },
      clickShowMore () {
        this.rows++
        this.filterServices()
      },
      isSelectedService (service) {
        if (typeof this.serviceSelectedItems !== 'undefined' && this.serviceSelectedItems.length > 0) {
          return this.serviceSelectedItems.some(function (element) {
            return element.id === service.id
          })
        } else {
          return true
        }
      },
      isNeedShowReset () {
        return (typeof this.serviceSelectedItems !== 'undefined' && this.serviceSelectedItems.length > 0)
      },
    },
  }
</script>
<style lang="sass">
  .card_service_active
    background-color: #121212 !important
    min-height: 331px !important
  .card_service
    background-color: #222222 !important
    opacity: 0.3 !important
    min-height: 331px !important
</style>
